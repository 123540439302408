// 登录模块
const INTERROGATION = [
  {
    path: '/login',
    name: 'login',
    // 按需加载路由
    component: () => import('@/views/login/Login'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    path: '/accompany/login',
    name: 'accompanylogin',
    // 按需加载路由
    component: () => import('@/views/Accompany/loGin'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    path: '/loginphone',
    name: 'loginphone',
    // 按需加载路由
    component: () => import('@/views/login/Loginphone'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    path: '/reg',
    name: 'reg',
    // 按需加载路由
    component: () => import('@/views/login/Reg'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    path: '/retrieve',
    name: 'retrieve',
    // 按需加载路由
    component: () => import('@/views/login/Retrieve'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default INTERROGATION
