import { LoadingBar } from 'view-design'

/**
 * 打开进度条
 */
export function loadingBarStart () {
  LoadingBar.start()
}

/**
 * 加载进度条
 */
export function loadingBarFinish () {
  LoadingBar.finish()
}

/**
 * 非正常关闭进度条
 */
export function loadingBarError () {
  LoadingBar.error()
}
