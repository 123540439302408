import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import STATE_KEY from '../config/store'
import REQUEST_KEY from '../config/request'
import createHelper from 'vue-router-keep-alive-helper'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
let childRoutes = []
// 路由去中心化
const routerModules = require.context('./modules', false, /\.js$/)
routerModules.keys().forEach(filename => {
  childRoutes = childRoutes.concat(routerModules(filename).default || routerModules(filename))
})
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    name: 'index',
    component: () => import('@/views/index')
  }, {
    path: '*',
    name: '401',
    component: () => import('@/views/error/401'),
    meta: {
      // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
      isLogin: false,
      // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/associationManageList',
    name: 'associationManageList',
    component: () => import('@/views/associationManage/associationManageList'),
    meta: {
      isLogin: false,
      // 是否需要鉴权
      isAuth: true,
      btnAuthList: []
    }
  },
  ...childRoutes
  ]
})
// // 管理缓存
createHelper({ Vue, router, replaceStay: ['/community/searchHome'] })
/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {
  // document.body.scrollTop = 0
  // document.documentElement.scrollTop = 0
  const isLogin = to.meta.isLogin || false
  if (!isLogin) {
    const isRegister = process.env.VUE_APP_IS_SHOW_REGISTER ? process.env.VUE_APP_IS_SHOW_REGISTER === 'false' : false
    const isPhone = process.env.VUE_APP_IS_PHONE_LOGIN ? process.env.VUE_APP_IS_PHONE_LOGIN === 'false' : false
    const isCode = process.env.VUE_APP_IS_SHOW_CODE ? process.env.VUE_APP_IS_SHOW_CODE === 'false' : false
    const { path } = to
    if (isRegister && path === '/register') {
      next({ path: '/' })
      return
    }
    if (isPhone && path === '/loginP') {
      next({ path: '/' })
      return
    }
    if (isCode && path === '/forgetCode') {
      next({ path: '/' })
      return
    }
    next()
  } else {
    const userId = store.getters.userInfo.userId
    if (userId) {
      const isAuth = to.meta.isAuth || false
      const userMenu = store.getters.userMenuAuth || []
      const arr = userMenu.filter(item => ('/' + item.linkUrl) === to.path && item.isShow === 1)
      if (arr.length > 0) {
        const currRouterInfo = arr[0]
        const permissions = currRouterInfo.permissions || ''
        to.meta.btnAuthList = permissions.split(',')
      }
      if (!isAuth || arr.length > 0) {
        next()
      } else {
        next({ path: REQUEST_KEY.serviceConfig.noPermissionUrl })
      }
    } else {
      next({ path: '/' })
    }
  }
})

/**
 * 返回首页
 */
export function goHome () {
  if (router.currentRoute.path !== REQUEST_KEY.serviceConfig.layoutUrl) {
    router.push(REQUEST_KEY.serviceConfig.layoutUrl)
  }
}

/**
 * 401页面
 */
export function goNoLimits () {
  if (router.currentRoute.path !== REQUEST_KEY.serviceConfig.noPermissionUrl) {
    router.push(REQUEST_KEY.serviceConfig.noPermissionUrl)
  }
}

/**
 * 返回登录页
 */
export function goLogin () {
  // 清空缓存
  localStorage.removeItem(process.env.VUE_APP_SYS_EN_NAME)
  // 重置state
  store.dispatch(STATE_KEY.resetState)
  if (router.currentRoute.path !== REQUEST_KEY.serviceConfig.loginUrl) {
    router.push(REQUEST_KEY.serviceConfig.loginUrl)
  }
}
/**
 * 返回我的页面
 */
export function goMain () {
  // 清空缓存
  localStorage.removeItem(STATE_KEY.resetUserState)
  // 重置state
  store.dispatch(STATE_KEY.resetState)
  if (router.currentRoute.path !== REQUEST_KEY.serviceConfig.loginUrl) {
    router.push(REQUEST_KEY.serviceConfig.MineUrl)
  }
}
/**
 * 医生用户端
 */
export function godoctorHome () {
  if (router.currentRoute.path !== REQUEST_KEY.serviceConfig.layoutUrl) {
    router.push(REQUEST_KEY.serviceConfig.doctorUrl)
  }
}
/**
 * 医生用户端
 */
export function goaccompanyHome () {
  if (router.currentRoute.path !== REQUEST_KEY.serviceConfig.layoutUrl) {
    router.push(REQUEST_KEY.serviceConfig.accompanyUrl)
  }
}
export default router
