/*
 * @Author: duanyunlong
 * @since: 2020-06-29 09:54:48
 * @lastTime: 2020-07-29 08:09:41
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\store\getters.js
 * @message: vuex getters对象
 */
/**
 * 所有vuex值获取都在当前模块获取
 * @type {{}}
 */
const GETTERS = {
  userInfo: state => state.user.userInfo,
  userMenu: state => state.user.userMenu,
  userMenuAuth: state => state.user.userMenuAuth,
  isRisk: state => state.app.isRisk,
  submitKey: state => state.app.submitKey,
  authToken: state => state.user.authToken,
  getIds: state => state.getid.getIds,
  getNames: state => state.getid.getNames,
  getdoctorId: state => state.getid.getDoctor,
  getDeartment: state => state.getid.getDeartment,
  resetGetDepartment: state => state.getid.resetGetDepartment,
  getwatdhlist: state => state.getid.watdhlist,
  olineList: state => state.getid.olineList,
  interrogationlist: state => state.getid.interrogationlist,
  doctorId: state => state.user.doctorId,
  accountUserId: state => state.user.accountUserId,
  roleList: state => state.user.roleList,
  patientinformation: state => state.getid.patientinformation,
  inquiryorder: state => state.getid.inquiryorder,
  accompanying: state => state.getid.accompanying,
  registered: state => state.doctor.registered,
  appointment: state => state.getid.appointment,
  firstdata: state => state.user.firstdata,
  getHospipalAdId: state => state.doctor.getHospipalAdId,
  videofromid: state => state.doctor.videofromid,
  searchlist: state => state.user.searchlist,
  receptionUser: state => state.getid.receptionUser
}
export default GETTERS
