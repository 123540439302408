import wxSdk from 'weixin-js-sdk' // h5分享用到
import { WxChatSharegetUrl } from '../api/modules/user/user' // 分享接口后台提供，用于获取appid，timestamp，nonceStr，signature
// h5分享功能
var Shares = {
  shareCode: function (imgurl, title, desc, link, callback) {
    // 记录进入app时的url
    if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
      window.entryUrl = location.href.split('#')[0]
    }
    // 进行签名的时候  Android 不用使用之前的链接， ios 需要
    var url = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.entryUrl
    const arr = {
      url: url
    }
    WxChatSharegetUrl(arr).then((res) => {
      wxSdk.config({
        debug: false, // 用来调试
        appId: res.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'onMenuShareTimeline'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      })
      wxSdk.ready(() => {
        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        wxSdk.updateAppMessageShareData({
          title: title, // 分享标题
          desc: desc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: imgurl, // 分享图标
          success: function (res) {
            console.log(res, 'success')
            // 用户确认分享后执行的回调函数
            // 用户确认分享后执行的回调函数
            if (callback) {
              // eslint-disable-next-line standard/no-callback-literal
              callback('success')
            }
          },
          cancel: function () {
          // 用户取消分享后执行的回调函数
            console.log('erro')
            if (callback) {
              // eslint-disable-next-line standard/no-callback-literal
              callback('cancel')
            }
          }
        })
        // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
        wxSdk.updateTimelineShareData({
          title: title, // 分享标题
          desc: desc, // 分享描述
          link: link, // 分享链接
          imgUrl: imgurl, // 分享图标
          success: function (res) {
          // 用户确认分享后执行的回调函数
            if (callback) {
              // eslint-disable-next-line standard/no-callback-literal
              callback('success', res)
            }
          },
          cancel: function () {
          // 用户取消分享后执行的回调函数
            if (callback) {
              // eslint-disable-next-line standard/no-callback-literal
              callback('cancel')
            }
          }
        })
      })
    })
  },
  login (code) {
    // 判断是否为微信环境
    const isWechat = () => {
      return (
        String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
    'micromessenger'
      )
    }
    if (isWechat) {
      // 微信appid
      const appid = 'wx36452f617e92f207' // 正式服
      // const appid = 'wx38e7bf72ec672eb3' // 测试服
      const local = 'https://douyih5.douyigyl.com'
      // const local = 'http://test.douyigyl.com'
      if (code == null || code === '') {
        // 不存在就打开上面的地址进行授权
        window.location.href =
          'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
          appid +
          '&redirect_uri=' +
          encodeURIComponent(local) +
          '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
      } else {
        return code
      }
    }
  }
}
export default Shares
