const enSysName = process.env.VUE_APP_SYS_EN_NAME
const COOKIE_KEY = {
  user: `${enSysName}-user`,
  userAccount: `${enSysName}-userAccount`,
  userId: `${enSysName}-userId`,
  rememberAccount: `${enSysName}-rememberAccount`,
  version: `${enSysName}-version`,
  accessToken: `${enSysName}-accessToken`
}
export default COOKIE_KEY
