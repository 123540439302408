// 医生端
const DOCTORSPORT = [
  {
    // 医生认证
    path: '/Accompanying/physiciancertified',
    name: 'physiciancertified',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Physiciancertified'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/index',
    name: 'doctorsportindex',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Index'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/mine',
    name: 'doctorsportmine',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Mine'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/interroga',
    name: 'interroga',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Interroga'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/chat',
    name: 'doctorchat',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Chat'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/revenue',
    name: 'revenue',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Revenue'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/withdrawals',
    name: 'withdrawals',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Withdrawals'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/withdrawallist',
    name: 'withdrawallist',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Withdrawallist'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/registered',
    name: 'Registered',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Registered'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/Verification',
    name: 'Verification',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Verification'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/doctorsport/writeoffdetails',
    name: 'writeoffdetails',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Writeoffdetails'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    path: '/doctorsport/videocall',
    name: 'VideoCall',
    // 按需加载路由
    component: () => import('@/views/doctorsport/VideoCall'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  // 拓客 Tourists
  {
    path: '/doctorsport/tourists',
    name: 'Tourists',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Tourists'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  // 医生资料
  {
    path: '/doctorsport/information',
    name: 'Information',
    // 按需加载路由
    component: () => import('@/views/doctorsport/Information'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default DOCTORSPORT
