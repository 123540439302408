/*
 * @Author: duanyunlong
 * @since: 2020-05-24 09:18:32
 * @lastTime: 2020-07-24 10:34:23
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\utils\mask.js
 * @message: 加载工具模块
 */
import { Spin } from 'view-design'
const mask = {
  /**
   * 计数键值对
   */
  numMap: {},
  /**
   * 默认的Key值
   */
  defaultKey: 'blc',
  /**
   * 初始化计数键值对
   * @param key
   */
  init (key = mask.defaultKey) {
    if (!this.numMap[key]) {
      this.numMap[key] = 0
    }
  }
}

/**
 * 显示遮罩层
 * @param key
 */
export function openMask (key = mask.defaultKey, loadingText) {
  mask.init(key)
  if (mask.numMap[key]++ === 0) {
    if (loadingText) {
      Spin.show({
        render: (h) => {
          return h('div', [
            h('Icon', {
              props: {
                type: 'ios-loading',
                size: 18
              }
            }),
            h('div', loadingText)
          ])
        }
      })
    } else {
      Spin.show()
    }
  }
}

/**
 * 关闭遮罩层
 * @param key
 * @param num 关闭的数据
 */
export function closeMask (key = mask.defaultKey, num = 1) {
  mask.numMap[key] -= num
  if (mask.numMap[key] <= 0) {
    mask.numMap[key] = 0
    Spin.hide()
  }
}
