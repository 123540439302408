// 问诊模块
const INTERROGATION = [
  {
    path: '/interrogation/hospital',
    name: 'hospital',
    // 按需加载路由
    component: () => import('@/views/interrogation/Hospital'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/interrogation/department',
    name: 'department',
    // 按需加载路由
    component: () => import('@/views/interrogation/Department'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/interrogation/doctorHomepage/:doctorId',
    name: 'doctorHomepage',
    // 按需加载路由
    component: () => import('@/views/interrogation/DoctorHomepage'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/interrogation/consultationPayment',
    name: 'consultationPayment',
    // 按需加载路由
    component: () => import('@/views/interrogation/ConsultationPayment'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/interrogation/onlineRegistration',
    name: 'onlineRegistration',
    // 按需加载路由
    component: () => import('@/views/interrogation/OnlineRegistration'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    path: '/interrogation/addpatients',
    name: 'addpatients',
    // 按需加载路由
    component: () => import('@/views/interrogation/Addpatients'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 线上购药
    path: '/interrogation/buymedicines',
    name: 'Buymedicines',
    // 按需加载路由
    component: () => import('@/views/interrogation/Buymedicines'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 更多评论
    path: '/interrogation/morecomments',
    name: 'MoreComments',
    // 按需加载路由
    component: () => import('@/views/interrogation/MoreComments'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 更多专家推荐
    path: '/interrogation/doctorrecommend',
    name: 'DoctorRecommend',
    // 按需加载路由
    component: () => import('@/views/interrogation/DoctorRecommend'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 更多医院推荐
    path: '/interrogation/hospitalrecom',
    name: 'hospitalrecom',
    // 按需加载路由
    component: () => import('@/views/interrogation/Hospitalrecom'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 医院详情
    path: '/interrogation/hospitaldetails/:id',
    name: 'hospitaldetails',
    // 按需加载路由
    component: () => import('@/views/interrogation/Hospitaldetails'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 待诊室
    path: '/interrogation/waitingroom',
    name: 'Waitingroom',
    // 按需加载路由
    component: () => import('@/views/interrogation/Waitingroom'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default INTERROGATION
