import STATE_KEY from '../../config/store'
// import { uuid } from '../../utils/data'
const GETID = {
  state: {
    getIds: {
    },
    getNames: {
    },
    getDoctor: {
    },
    getDeartment: {
    },
    watdhlist: {
    },
    olineList: {
    },
    interrogationlist: {
    },
    patientinformation: {},
    inquiryorder: {
    },
    accompanying: {
    },
    appointment: {
    },
    receptionUser: {
    }
  },
  mutations: {
    /**
     * 存储id值
     * @param state 当前state状态
     * @param list 传入的user值
     */
    [STATE_KEY.saveGetIds] (state, list) {
      // 医院id
      state.getIds.hospitalId = list.hospitalId
    },
    /**
     * 存储标题名值
     * @param state 当前state状态
     * @param list 传入的user值
     */
    [STATE_KEY.saveGetNames] (state, list) {
      // 医院名称
      state.getNames.hospitalName = list.hospitalName
    },
    /**
     *存储医生id
     * @param  state
     * @param  list
     */
    [STATE_KEY.saveGetDoctor] (state, list) {
      state.getDoctor.id = list.id
      state.getDoctor.authoState = list.authoState
      state.getDoctor.accountName = list.accountName
      state.getDoctor.doctorsImg = list.doctorsImg
      state.getDoctor.certificate = list.certificate
      state.getDoctor.administrativeName = list.administrativeName
      state.getDoctor.wprice = list.wprice
      state.getDoctor.gprice = list.gprice
      state.getDoctor.videoPrice = list.videoPrice
      state.getDoctor.accountUserId = list.accountUserId
      state.getDoctor.orgName = list.orgName
    },
    /**
     *存储科室id
     * @param  state
     * @param  list
     */
    [STATE_KEY.saveGetDepartment] (state, list) {
      // 科室id
      state.getDeartment.departmentId = list.departmentId
    },
    // 重置科室id
    /**
     * 重置当前模块
     * @param {}} state
     */
    [STATE_KEY.resetGetDepartment] (state) {
      state.getDeartment.departmentId = ''
    },
    /**
     *存储粉丝与关注列表
     * @param  state
     * @param  list
     */
    [STATE_KEY.saveGetWatdhlist] (state, list) {
      state.watdhlist.watdhId = list.watdhId
    },
    /**
     *存储订单信息列表
     * @param  state
     * @param  list
     */
    [STATE_KEY.saveGetOlineList] (state, list) {
      state.olineList.appointmentTimeId = list.id
      state.olineList.title = list.title
    },
    /**
     *存储问诊信息
     * @param  state
     * @param  list
     */
    [STATE_KEY.saveGetInterrogationlist] (state, list) {
      state.interrogationlist.content = list.content
      state.interrogationlist.file = list.file
    },
    /**
     *存储医患人员id
     * @param  state
     * @param  list
     */
    [STATE_KEY.saveGetPatientInformation] (state, list) {
      state.patientinformation.id = list.id
      state.patientinformation.type = list.type
      state.patientinformation.name = list.name
    },
    /**
     *重置医患人员id
     * @param  state
     * @param  list
     */
    [STATE_KEY.resetGetPatientInformation] (state) {
      state.patientinformation = {}
    },
    /**
     *存储医患信息订单id
     * @param  state
     * @param  list
     */
    [STATE_KEY.saveGetInquiryOrder] (state, list) {
      state.inquiryorder.id = list.id
      state.inquiryorder.consultationStatus = list.consultationStatus
    },
    /**
     *存储新增时间信息
     * @param  state
     * @param  list
     */
    [STATE_KEY.saveGetAppointment] (state, list) {
      state.appointment.appointmentTime = list.appointmentTime
      state.appointment.appointmentType = list.appointmentType
      state.appointment.doctorId = list.doctorId
    },
    //  [STATE_KEY.saveUserInfo] (state, user) {
    //     state.userInfo.userId = user.id
    //     state.userInfo.userName = user.userName
    //     state.userInfo.userAccount = user.userAccount
    //     state.userInfo.headPath = user.headPath ? user.headPath : ''
    //   },
    // /**
    //  *  更改isRisk状态
    //  * @param {Boolean} flag
    //  */
    // [STATE_KEY.updateRiskState] (state, flag) {
    //   state.isRisk = flag
    // },
    // /**
    //  * 重置submitKey
    //  * @param {String} state
    //  */
    // [STATE_KEY.resetSubmitKey] (state) {
    //   state.submitKey = uuid(16, 16)
    // },
    // /**
    //  * 重置当前模块
    //  * @param state
    //  */
    // [STATE_KEY.resetAppState] (state) {
    //   state.isRisk = false
    //   state.submitKey = uuid(16, 16)
    // }
    /**
     *存储医陪订单信息
     * @param  state
     * @param  list
     */
    [STATE_KEY.saveGetAccompanying] (state, list) {
      state.accompanying.accountName = list.accountName
      state.accompanying.visitTime = list.visitTime
      state.accompanying.visitAddress = list.visitAddress
      state.accompanying.visitHospital = list.visitHospital
      state.accompanying.visitDepartment = list.visitDepartment
      state.accompanying.visitDescribe = list.visitDescribe
      state.accompanying.visitDuration = list.visitDuration
      state.accompanying.orderMoney = list.orderMoney
      state.accompanying.phoneNumber = list.phoneNumber
      state.accompanying.id = list.id
    },
    // 储存私信id
    [STATE_KEY.saveReceptionUser] (state, list) {
      // 医院id
      state.receptionUser = list
    }
  }
}
export default GETID
