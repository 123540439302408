import * as cookies from 'js-cookie'
/**
 * 获取cookie值
 * @param key
 * @returns {*}
 */
export function getCookieItem (key) {
  return cookies.get(key)
}

/**
 * 设置cookie
 * @param key
 * @param value
 * @param params
 */
export function setCookieItem (key, value, params = {}) {
  cookies.set(key, value, params)
}

/**
 * 移除cookie
 * @param key
 * @param param
 */
export function removeCookieItem (key, param) {
  if (param) {
    cookies.remove(key, param)
  } else {
    cookies.remove(key)
  }
}

/**
 * 获取cookie的json数据
 * @param key
 * @returns {*}
 */
export function cookieData (key) {
  if (key) {
    return cookies.getJSON(key)
  } else {
    return cookies.getJSON()
  }
}
