/**
 * 加密模块
 */
const cryptoJS = require('crypto-js')
const param = {
  // 十六位十六进制数作为密钥
  key: cryptoJS.enc.Utf8.parse('1024102410ABCDEF'),
  // 十六位十六进制数作为密钥偏移量
  iv: cryptoJS.enc.Utf8.parse('ABCDEF1024102410')
}
const config = [param.key, { iv: param.iv, mode: cryptoJS.mode.CBC, padding: cryptoJS.pad.Pkcs7 }]
/**
 * 解密方法
 * @param word
 * @returns {string}
 */
export function decrypt (word) {
  const encryptedHexStr = cryptoJS.enc.Hex.parse(word)
  const decrypt = cryptoJS.AES.decrypt(cryptoJS.enc.Base64.stringify(encryptedHexStr), ...config)
  return decrypt.toString(cryptoJS.enc.Utf8).toString()
}

/**
 * 加密方法
 * @param word
 * @returns {string}
 */
export function encrypt (word) {
  const encrypted = cryptoJS.AES.encrypt(cryptoJS.enc.Utf8.parse(word), ...config)
  return encrypted.ciphertext.toString().toUpperCase()
}
