const COMMUNITY = [
  // 社区
  {
    // 社区首页
    path: '/community/index',
    name: 'index',
    // 按需加载路由
    component: () => import('@/views/community/index'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 搜索页
    path: '/community/searchHome',
    name: 'searchHome',
    // 按需加载路由
    component: () => import('@/views/community/SearchHome'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 直播页
    path: '/community/livebroadcast',
    name: 'livebroadcast',
    // 按需加载路由
    component: () => import('@/views/community/Livebroadcast'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 我的作品
    path: '/community/myvideo',
    name: 'myvideo',
    // 按需加载路由
    component: () => import('@/views/community/MyVideo'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 我的作品
    path: '/community/videodetail',
    name: 'videodetail',
    // 按需加载路由
    component: () => import('@/views/community/VideoDetail'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default COMMUNITY
