/*
 * @Author: duanyunlong
 * @since: 2020-07-08 10:55:40
 * @lastTime: 2020-07-29 08:10:52
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\store\modules\user.js
 * @message: vuex 用户模块
 */
import STATE_KEY from '../../config/store'
import { typeOf } from '../../utils/verification'
import { getListByTree } from '../../utils/data'

const USER = {
  state: {
    // 用户信息
    userInfo: {},
    // 用户菜单
    userMenu: [],
    // 用于权限判断及路由定位
    userMenuAuth: [],
    authToken: '',
    roleList: [],
    doctorId: {},
    accountUserId: {},
    firstdata: {
    },
    searchlist: []
  },
  mutations: {
    /**
     *
     * @param {*} state 当前state状态
     * @param {*} user 传入的role值
     */
    saveRoleList (state, role) {
      state.roleList = role
    },
    /**
     * 存储用户信息
     * @param state 当前state状态
     * @param user 传入的user值
     */
    [STATE_KEY.saveUserInfo] (state, user) {
      state.userInfo.userId = user.id
      state.userInfo.userName = user.userName
      state.userInfo.userAccount = user.userAccount
      state.userInfo.headPath = user.headPath ? user.headPath : ''
    },
    /**
     * 更改头像
     * @param state
     * @param iconPath 头像地址
     */
    [STATE_KEY.updateHeader] (state, headPath) {
      state.userInfo.headPath = headPath || ''
    },
    /**
     * 存储用户菜单
     * @param state
     * @param list
     */
    [STATE_KEY.saveUserMenu] (state, list) {
      const userMenu = typeOf(list) === 'array' ? list : []
      state.userMenu = userMenu
      let userMenuAuth = userMenu
      userMenuAuth = getListByTree(userMenuAuth)
      state.userMenuAuth = userMenuAuth
    },
    /**
     *存储用户token
     * @param {} state
     * @param {String} token
     */
    [STATE_KEY.saveAuthToken] (state, token) {
      state.authToken = token
    },
    /**
     * 重置当前模块
     * @param {}} state
     */
    [STATE_KEY.resetUserState] (state) {
      state.userInfo = {
        id: '',
        userName: '',
        userAccount: '',
        headPath: ''
      }
      state.userMenu = []
      state.userMenuAuth = []
      state.authToken = ''
    },
    /**
     * 储存用户医生id
     * @param {}} state
     */
    [STATE_KEY.saveGetUserdoctor] (state, list) {
      // 用户医生
      state.doctorId.id = list.id
    },
    /**
     * 储存患者id
     * @param {}} state
     */
    [STATE_KEY.saveGetUser] (state, list) {
      // 用户医生
      state.accountUserId = list
    },
    /**
     * 用户是否第一次进来
     * @param {}} state
     */
    [STATE_KEY.saveFrist] (state, list) {
      // 用户医生
      state.firstdata = list
    },
    /**
     * 储存用户搜索历史记录
     * @param {}} state
     */
    [STATE_KEY.savaGetSearchHistory] (state, list) {
      console.log(list)
      state.searchlist = list
    }
  }
}

export default USER
