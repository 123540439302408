/*
 * @Author: duanyunlong
 * @since: 2020-05-24 09:18:32
 * @lastTime: 2020-07-28 10:18:48
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\router\modules\error.js
 * @message: 错误页等路由规则
 */
const MEESAGE = [
  {
    path: '/message/messagecenter',
    name: 'Messagecenter',
    // 按需加载路由
    component: () => import('@/views/message/Messagecenter'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default MEESAGE
