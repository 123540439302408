/*
 * @message: 医生介绍
 */
const INTRODUCE = [{
  path: '/introduce',
  name: 'introduce',
  // redirect: '/introduce/:code',
  // 按需加载路由
  component: () => import('@/views/introduce/doctor_introduce'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: false,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: false,
    btnAuthList: []
  }
}, {
  path: '/mcninstitution',
  name: 'mcninstitution',
  // redirect: '/introduce/:code',
  // 按需加载路由
  component: () => import('@/views/introduce/MCNinstitution'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录，不挂载该参数或该参数为false，表示不需要登录
    isLogin: false,
    // 是否需要鉴权，不挂载该参数或该参数为false，表示不需要鉴权
    isAuth: false,
    btnAuthList: []
  }
}]
export default INTRODUCE
