import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import app from './modules/app'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import STATE_KEY from '../config/store'
import getid from './modules/getid'
import doctor from './modules/doctor'
const ls = new SecureLS()
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    app,
    getid,
    doctor
  },
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
    /**
     * 重置state
     * @param commit
     */
    [STATE_KEY.resetState] ({ commit }) {
      commit(STATE_KEY.resetUserState)
      commit(STATE_KEY.resetAppState)
    }
  },
  plugins: [
    createPersistedState({
      key: `${process.env.VUE_APP_SYS_EN_NAME}_vuex`,
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
})
