// 医陪
const ACCOMPANYING = [
  {
    // 用户端增值确认--任务详情
    path: '/Accompanying/addedservices/:orderId',
    name: 'addedservices',
    // 按需加载路由
    component: () => import('@/views/Accompanying/Addedservices'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 新增医陪订单
    path: '/Accompanying/newtasks/:personId?',
    name: 'newtasks',
    // 按需加载路由
    component: () => import('@/views/Accompanying/Newtasks'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 发布评价
    path: '/Accompanying/publishreview/:orderId',
    name: 'publishreview',
    // 按需加载路由
    component: () => import('@/views/Accompanying/Publishreview'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 任务列表
    path: '/Accompanying/ordermanagement',
    name: 'ordermanagement',
    // 按需加载路由
    component: () => import('@/views/Accompanying/Ordermanagement'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 聊天
    path: '/Accompanying/communicate',
    name: 'communicate',
    // 按需加载路由
    component: () => import('@/views/Accompanying/communicate'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 医陪画像
    path: '/Accompanying/accompanyList',
    name: 'accompanyList',
    // 按需加载路由
    component: () => import('@/views/Accompanying/accompanyList'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 医陪画像-个人信息
    path: '/Accompanying/portraitPersonal/:personId',
    name: 'portraitPersonal',
    // 按需加载路由
    component: () => import('@/views/Accompanying/PortraitPersonal'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default ACCOMPANYING
