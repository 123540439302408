/**
 * vue全局过滤器
 */

/**
 * 时间过滤器
 * @param val 值
 * @param format 格式
 */
const globalFilters = {}
globalFilters.install = function (Vue) {
  Vue.filter('dateFormat', (val, format) => {
    return val
  })
  Vue.filter('Score', function (value) {
    let score = ''
    switch (value) {
      case 1:
        score = '非常不满意'
        break
      case 2:
        score = '不满意'
        break
      case 3:
        score = '一般'
        break
      case 4:
        score = '比较满意'
        break
      case 5:
        score = '非常满意'
        break
    }
    return score
  })
  Vue.filter('Sex', function (value) {
    let sex = ''
    if (value % 2 === 0) {
      sex = '女'
    } else {
      sex = '男'
    }
    return sex
  })
  Vue.filter('formatName', function (value) {
    if (!value) return ''
    let str = value
    console.log(str.length)
    if (str.length === 2) {
      str = str.replace(value.substring(1, value.length), '*')
    }
    if (str.length === 3) {
      str = str.replace(value.substring(1, value.length), '**')
    }
    if (str.length === 4) {
      str = str.replace(value.substring(1, value.length), '***')
    }
    return str
  })
  Vue.filter('Age', function (value) {
    const birthdays = new Date(value.replace(/-/g, '-'))
    const d = new Date()
    const age =
          d.getFullYear() -
          birthdays.getFullYear() -
          (d.getMonth() < birthdays.getMonth() ||
          (d.getMonth() === birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
            ? 1
            : 0)
    return age
  })
  Vue.filter('ConsumptionType', function (value) {
    let consumptionname = ''
    if (value === 0) {
      consumptionname = '图文问诊'
    } else if (value === 4) {
      consumptionname = '视频问诊'
    } else {
      consumptionname = '线上挂号'
    }
    return consumptionname
  })
  Vue.filter('orderStatus', function (value) {
    let orderStatusname = ''
    switch (value) {
      case 0 : orderStatusname = '未核销'
        break
      case 1 : orderStatusname = '已核销'
        break
      case 2 : orderStatusname = '已取消'
        break
    }
    return orderStatusname
  })
  Vue.filter('Number', function (value) {
    let Numbername = ''
    if (value > 999 && value < 9999) {
      Numbername = (value / 1000).toFixed(1) + '千'
    } else if (value > 9999) {
      Numbername = (value / 10000).toFixed(1) + '万'
    } else {
      Numbername = value + '位'
    }
    return Numbername
  })
  Vue.filter('videoNumber', function (value) {
    let Numbername = ''
    if (value > 999 && value < 9999) {
      Numbername = (value / 1000).toFixed(1) + 'K'
    } else if (value > 9999) {
      Numbername = (value / 10000).toFixed(1) + 'W'
    } else {
      Numbername = value
    }
    return Numbername
  })
  Vue.filter('dateTimeStamp', function (value) {
    const timestamp = new Date(value).getTime()
    var minute = 1000 * 60 // 把分，时，天，周，半个月，一个月用毫秒表示
    var hour = minute * 60
    var day = hour * 24
    var week = day * 7
    var month = day * 30
    let result = ''
    var now = new Date().getTime()// 获取当前时间毫秒
    var diffValue = now - timestamp // 时间差

    if (diffValue < 0) {
      return
    }
    var minC = diffValue / minute // 计算时间差的分，时，天，周，月
    var hourC = diffValue / hour
    var dayC = diffValue / day
    var weekC = diffValue / week
    var monthC = diffValue / month
    // 此处考虑小数情况，感谢 情非得已https://blog.csdn.net/weixin_48495574 指正
    if (monthC >= 1 && monthC < 4) {
      result = ' ' + parseInt(monthC) + '月前'
    } else if (weekC >= 1 && weekC < 5) {
      result = ' ' + parseInt(weekC) + '周前'
    } else if (dayC >= 1 && dayC < 7) {
      result = ' ' + parseInt(dayC) + '天前'
    } else if (hourC >= 1 && hourC < 24) {
      result = ' ' + parseInt(hourC) + '小时前'
    } else if (minC >= 1 && minC < 60) {
      result = ' ' + parseInt(minC) + '分钟前'
    } else if (diffValue >= 0 && diffValue <= minute) {
      result = '刚刚'
    } else {
      var datetime = new Date()
      datetime.setTime(timestamp)
      var Nyear = datetime.getFullYear()
      var Nmonth = datetime.getMonth() + 1 < 10 ? '0' + (datetime.getMonth() + 1) : datetime.getMonth() + 1
      var Ndate = datetime.getDate() < 10 ? '0' + datetime.getDate() : datetime.getDate()
      result = Nyear + '-' + Nmonth + '-' + Ndate
    }
    return result
  })
}
export default globalFilters
