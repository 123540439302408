/*
 * @Author: duanyunlong
 * @since: 2020-07-09 09:59:29
 * @lastTime: 2020-07-29 11:42:03
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\api\modules\login\login.js
 * @message: 登录相关api
 */
/**
 * 登陆
 * @param params 参数
 */
import { requestGet, requestPost, requestPut } from '../../../utils/request'
import API_KEY from '../../index'
import REQUEST_KEY from '../../../config/request'

/**
 * 登陆
 * @param params
 * @returns {*}
 */
export function login (params) {
  return requestPost(API_KEY.login.login, params, REQUEST_KEY.requestMode.json, REQUEST_KEY.responseType.json, { isError: true })
}
/**
 * 手机登录
 * @author Enzo
 * @date 2020-6-28
 */
export function loginPhone (params) {
  return requestPost(API_KEY.login.loginPhone, params, REQUEST_KEY.requestMode.json, REQUEST_KEY.responseType.json, { isError: true })
}

/**
 * 邮箱登录
 * @author Enzo
 * @date 2020-6-28
 */
export function loginEmail (params) {
  return requestPost(API_KEY.login.loginEmail, params, REQUEST_KEY.requestMode.json, REQUEST_KEY.responseType.json, { isError: true })
}

/**
 * 退出
 * @returns {*}
 */
export function loginOut () {
  return requestGet(API_KEY.login.loginOut, {})
}

/**
 * 获取rsa公钥
 * @author Enzo
 * @date 2020-6-28
 */
export function getRsaPublicKey () {
  return requestGet(API_KEY.login.getRsaPublicKey, {}, REQUEST_KEY.responseType.json, { isError: true })
}

/**
 * 钉钉扫码登录
 * @author Enzo
 * @date 2020-7-1
 */
export function dingTalkQrLogin (params) {
  return requestPost(API_KEY.dingTalkAuthentication.dingTalkQrLogin, params, REQUEST_KEY.requestMode.formData, REQUEST_KEY.responseType.json, { isError: true })
}
/**
 * 微信扫码登录
 * @param {Object} params
 */
export function weChatLogin (params) {
  return requestPost(API_KEY.login.weChatLogin, params, REQUEST_KEY.requestMode.formData, REQUEST_KEY.responseType.json, { isError: true })
}

/**
 * 绑定钉钉账号
 */
export function bindDingTalk (params) {
  return requestPost(API_KEY.dingTalkAuthentication.bindDingTalk, params, REQUEST_KEY.requestMode.formData)
}

/**
 * 解绑钉钉账号
 */
export function unBindDingTalk () {
  return requestPut(API_KEY.dingTalkAuthentication.unBindDingTalk, REQUEST_KEY.requestMode.formData)
}
/**
 * 获取钉钉配置信息
 */
export function getDingTalkAuthentication () {
  return requestGet(API_KEY.dingTalkAuthentication.readDingTalkAuthentication)
}
/**
 * 获取微信配置参数
 */
export function getWeChatAuthentication () {
  return requestGet(API_KEY.weChatAuthentication.readtWeChatAuthentication)
}
