import STATE_KEY from '../../config/store'
const DOCTOR = {
  state: {
    registered: {
    },
    videofromid: '',
    myVideoNum: ''
  },
  mutations: {
    /**
     * 存储id值
     * @param state 当前state状态
     * @param list 传入的user值
     */
    [STATE_KEY.saveGetRegistered] (state, list) {
      state.registered = list
    },
    /**
     * 储存广告位医院id
     * @param state
     * @param list
     */
    [STATE_KEY.saveGetHospitalAd] (state, list) {
      state.getHospipalAdId = list
    },
    /**
     * 储存广告位医院id
     * @param state
     * @param list
     */
    [STATE_KEY.saveGetVideofromid] (state, list) {
      state.videofromid = list
    }
  }
}
export default DOCTOR
