/*
 * @Author: duanyunlong
 * @since: 2020-05-24 09:18:32
 * @lastTime: 2020-07-28 10:18:48
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\router\modules\error.js
 * @message: 错误页等路由规则
 */
const TOPIC = [
  {
    // 圈子详情
    path: '/topic/topiccircle/:id/:color',
    name: 'Topictopiccircle',
    // 按需加载路由
    component: () => import('@/views/Topic/Topiccircle'),
    // meta，具体用法请参照vue-router文档
    meta: {
    // 是否需要登录
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 圈子病友互助列表
    path: '/topic/mutualpatients/:id/:color',
    name: 'mutualpatients',
    // 按需加载路由
    component: () => import('@/views/Topic/Mutualpatients'),
    // meta，具体用法请参照vue-router文档
    meta: {
    // 是否需要登录
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 病友互助详情
    path: '/topic/questionDetails/:id',
    name: 'questionDetails',
    // 按需加载路由
    component: () => import('@/views/Topic/questionDetails'),
    // meta，具体用法请参照vue-router文档
    meta: {
    // 是否需要登录
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 新增问答/病友互助
    path: '/topic/newQuestion/:circleId',
    name: 'newQuestion',
    // 按需加载路由
    component: () => import('@/views/Topic/newQuestion'),
    // meta，具体用法请参照vue-router文档
    meta: {
    // 是否需要登录
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 文章详情
    path: '/topic/articleParticulars/:id',
    name: 'articleParticulars',
    // 按需加载路由
    component: () => import('@/views/Topic/ArticleParticulars'),
    // meta，具体用法请参照vue-router文档
    meta: {
    // 是否需要登录
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 用户所添圈子详情
    path: '/topic/articleDetail/:id/:color',
    name: 'articleDetail',
    // 按需加载路由
    component: () => import('@/views/Topic/articleDetail'),
    // meta，具体用法请参照vue-router文档
    meta: {
    // 是否需要登录
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 圈子集合
    path: '/topic/articleList/:id',
    name: 'articleList',
    // 按需加载路由
    component: () => import('@/views/Topic/articleList'),
    // meta，具体用法请参照vue-router文档
    meta: {
    // 是否需要登录
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 新增圈子
    path: '/topic/newCircle/:circleId',
    name: 'newCircle',
    // 按需加载路由
    component: () => import('@/views/Topic/newCircle'),
    // meta，具体用法请参照vue-router文档
    meta: {
    // 是否需要登录
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default TOPIC
