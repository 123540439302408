/*
 * @Author: duanyunlong
 * @since: 2020-07-16 17:04:31
 * @lastTime: 2020-07-29 09:16:41
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\api\modules\login\saveLoginInfo.js
 * @message: 存储登录信息模块
 */
import { setCookieItem } from '../../../utils/cookie'
import { getCurrMenu } from '../../../api/modules/system/menu'
import STATE_KEY from '../../../config/store'
import COOKIE_KEY from '../../../config/cookie'
import { encrypt } from '../../../utils/encrypt'
import message from '../../../utils/message'
import store from '../../../store/index'
import { godoctorHome, goaccompanyHome } from '../../../router'
/**
 * 登录用户信息存储
 */
export function saveLoginInfo (data) {
  // 存放 authToken
  store.commit(STATE_KEY.saveAuthToken, data.data.authToken)
  // 获取当前用户菜单等信息
  getCurrMenu().then((menuData) => {
    // 存放用户菜单信息
    store.commit(STATE_KEY.saveUserMenu, menuData.data)
    // 存储用户信息
    store.commit(STATE_KEY.saveUserInfo, data.data)
    store.commit('saveRoleList', data.data.roleList)
    // 加密用户名存储到cookie(设置存储时效为3天)
    setCookieItem(COOKIE_KEY.userAccount, encrypt(JSON.stringify(data.data.userAccount)), { expires: 3 })
    // 清除其他消息通知
    message.message.remove()
    // 提示用户是否登录成功
    message.message.success(data.message + '，正在为你跳转至首页', {}, () => {
      godoctorHome()
    })
  })
}
export function saveLoginInfoTwo (data) {
  // 存放 authToken
  store.commit(STATE_KEY.saveAuthToken, data.data.authToken)
  // 获取当前用户菜单等信息
  getCurrMenu().then((menuData) => {
    // 存放用户菜单信息
    store.commit(STATE_KEY.saveUserMenu, menuData.data)
    // 存储用户信息
    store.commit(STATE_KEY.saveUserInfo, data.data)
    store.commit('saveRoleList', data.data.roleList)
    // 加密用户名存储到cookie(设置存储时效为3天)
    setCookieItem(COOKIE_KEY.userAccount, encrypt(JSON.stringify(data.data.userAccount)), { expires: 3 })
    // 清除其他消息通知
    message.message.remove()
    // 提示用户是否登录成功
    message.message.success(data.message + '，正在为你跳转至首页', {}, () => {
      goaccompanyHome()
    })
  })
}
