const ACCOMPANY = [
  {
    // 抢单
    path: '/Accompany/graborders',
    name: 'graborders',
    // 按需加载路由
    component: () => import('@/views/Accompany/Graborders'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 医陪端任务详情
    path: '/Accompany/taskdetails',
    name: 'taskdetails',
    // 按需加载路由
    component: () => import('@/views/Accompany/Taskdetails'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 医陪端月嫂任务详情
    path: '/Accompany/taskdetailslink',
    name: 'taskdetailslink',
    // 按需加载路由
    component: () => import('@/views/Accompany/Taskdetailslink'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 医陪端任务详情
    path: '/Accompany/appreciationservice/:orderId',
    name: 'appreciationservice',
    // 按需加载路由
    component: () => import('@/views/Accompany/Appreciationservice'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 医陪认证
    path: '/Accompany/medicalauthentication',
    name: 'medicalauthentication',
    // 按需加载路由
    component: () => import('@/views/Accompany/Medicalauthentication'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 资料审核中
    path: '/Accompany/audit/:title',
    name: 'audit',
    // 按需加载路由
    component: () => import('@/views/Accompany/Audit'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 首页
    path: '/Accompany/home',
    name: 'home',
    // 按需加载路由
    component: () => import('@/views/Accompany/Home'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }, {
    // 我的
    path: '/Accompany/mine',
    name: 'mine',
    // 按需加载路由
    component: () => import('@/views/Accompany/Mine'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default ACCOMPANY
