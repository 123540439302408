/*
 * @Author: duanyunlong
 * @since: 2020-05-24 09:18:32
 * @lastTime: 2020-07-29 10:14:14
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\config\store.js
 * @message: vuex常量等配置
 */
/**
 * vuex方法变量配置
 */
const STATE_KEY = {
  saveUserInfo: 'SAVE_USER_INFO',
  updateHeader: 'UPDATE_HEADER',
  saveUserMenu: 'SAVE_USER_MENU',
  resetState: 'RESET_STATE',
  resetSubmitKey: 'RESET_SUBMIT_KEY',
  resetUserState: 'RESET_USER_STATE',
  resetAppState: 'RESET_APP_STATE',
  updateRiskState: 'UPDATE_RISK_STATE',
  saveAuthToken: 'SAVE_AUTH_TOKEN',
  saveGetIds: 'SAVE_GET_IDS',
  saveGetNames: 'SAVE_GET_NAMES',
  saveGetDoctor: 'SAVE_GET_DOCTOR',
  saveGetDepartment: 'SAVE_GET_DEPARTMENT',
  resetGetDepartment: 'RESET_GET_DEPARTMENT',
  saveGetWatdhlist: 'SAVE_GET_WATDHLIST',
  saveGetOlineList: 'SAVE_GET_OLINELIST',
  saveGetInterrogationlist: 'SAVE_GET_INTERROGATIONLIST',
  saveGetUserdoctor: 'SAVE_GET_USER_DOCTOR',
  saveGetUser: 'SAVE_GET_USER',
  saveGetPatientInformation: 'SAVE_GET_PATIENT_INFORMATION',
  resetGetPatientInformation: 'RESET_GET_PATIENT_INFORMATION',
  saveGetInquiryOrder: 'SAVE_GET_INQUIRY_ORDER',
  saveGetAccompanying: 'SAVE_GET_ACCOMPANYING',
  saveGetRegistered: 'SACE_GET_REGISTERED',
  saveGetAppointment: 'SAVE_GET_APPOINTMENT',
  saveFrist: 'SAVE_FRIST',
  saveGetHospitalAd: 'SAVE_GET_HOSPITALAD',
  saveGetVideofromid: 'SAVE_GET_VIDEOFROMID',
  savaGetSearchHistory: 'SAVE_GET_SEARCHHISTORY',
  saveReceptionUser: 'SAVE_RECEPTION_USER'
}
export default STATE_KEY
