import TIM from 'tim-js-sdk'
// 发送图片、文件等消息需要腾讯云即时通信 IM 上传插件
import TIMUploadPlugin from 'tim-upload-plugin'
import COS from 'cos-js-sdk-v5'

const options = {
  SDKAppID: 1400411915 // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
}
// 创建 SDK 实例，TIM.create() 方法对于同一个 SDKAppID 只会返回同一份实例
const tim = TIM.create(options) // SDK 实例通常用 tim 表示

// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
tim.setLogLevel(0) // 普通级别，日志量较多，接入时建议使用
// tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })
// 注册 COS SDK 插件
tim.registerPlugin({ 'cos-js-sdk': COS })
// main.js
// 监听事件，例如：
tim.on(TIM.EVENT.SDK_READY, function (event) {
  // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
  // event.name - TIM.EVENT.SDK_READY
})

// tim.on(TIM.EVENT.MESSAGE_RECEIVED, function (event) {
//   console.log(event)
//   // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
//   // event.name - TIM.EVENT.MESSAGE_RECEIVED
//   // event.data - 存储 Message 对象的数组 - [Message]
// })

export default tim
