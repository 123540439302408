/*
 * @Author: duanyunlong
 * @since: 2020-07-16 17:04:31
 * @lastTime: 2020-07-28 10:18:56
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\router\modules\index.js
 * @message: 首页
 */
const USER = [
  {
    // 我的预约
    path: '/user/appointment',
    name: 'Appointment',
    // 按需加载路由
    component: () => import('@/views/user/Appointment'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 作品数
    path: '/user/towork',
    name: 'Towork',
    // 按需加载路由
    component: () => import('@/views/user/Towork'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 个人主页
    path: '/user/personalhomepage',
    name: 'Personalhomepage',
    // 按需加载路由
    component: () => import('@/views/user/Personalhomepage'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 关注/粉丝
    path: '/user/watchlist',
    name: 'Watchlist',
    // 按需加载路由
    component: () => import('@/views/user/Watchlist'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 我的收藏
    path: '/user/toCollect',
    name: 'ToCollect',
    // 按需加载路由
    component: () => import('@/views/user/ToCollect'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 我的收藏视频详情
    path: '/user/collectVideo',
    name: 'CollectVideo',
    // 按需加载路由
    component: () => import('@/views/user/collectVideo'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 问诊记录
    path: '/user/consultationrecords',
    name: 'Consultationrecords',
    // 按需加载路由
    component: () => import('@/views/user/Consultationrecords'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 我的钱包
    path: '/user/mypurse',
    name: 'Mypurse',
    // 按需加载路由
    component: () => import('@/views/user/Mypurse'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 充值记录
    path: '/user/toprecords',
    name: 'Toprecords',
    // 按需加载路由
    component: () => import('@/views/user/Toprecords'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 患者信息
    path: '/user/addpatients',
    name: 'Addpatients',
    // 按需加载路由
    component: () => import('@/views/user/Addpatients'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 消费记录
    path: '/user/consumptiondetails',
    name: 'Consumptiondetails',
    // 按需加载路由
    component: () => import('@/views/user/Consumptiondetails'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 我的直播
    path: '/user/mylivestream',
    name: 'Mylivestream',
    // 按需加载路由
    component: () => import('@/views/user/Mylivestream'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 关于我们
    path: '/user/aboutus',
    name: 'Aboutus',
    // 按需加载路由
    component: () => import('@/views/user/Aboutus'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 收货地址
    path: '/user/shippingaddress',
    name: 'Shippingaddress',
    // 按需加载路由
    component: () => import('@/views/user/Shippingaddress'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 客服中心
    path: '/user/customeservicecenter',
    name: 'CustomerServiceCenter',
    // 按需加载路由
    component: () => import('@/views/user/CustomerServiceCenter'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 订单中心
    path: '/user/unpaid',
    name: 'Unpaid',
    // 按需加载路由
    component: () => import('@/views/user/Unpaid'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  },
  {
    // 健康档案
    path: '/user/healthrecords',
    name: 'Healthrecords',
    // 按需加载路由
    component: () => import('@/views/user/Healthrecords'),
    // meta，具体用法请参照vue-router文档
    meta: {
      // 是否需要登录访问
      isLogin: false,
      // 是否需要鉴权
      isAuth: false,
      btnAuthList: []
    }
  }
]
export default USER
