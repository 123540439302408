import TRTCCalling from 'trtc-calling-js'

export function createTrtcCalling () {
  return new TRTCCalling({
    SDKAppID: 1400411915
  })
}
export function formateTime (num) {
  if (typeof num === 'number') {
    if (num <= 0) {
      return '00:00:00'
    } else {
      const hh = parseInt(num / 3600)
      const shh = num - hh * 3600
      const ii = parseInt(shh / 60)
      const ss = shh - ii * 60
      return (hh < 10 ? '0' + hh : hh) + ':' + (ii < 10 ? '0' + ii : ii) + ':' + (ss < 10 ? '0' + ss : ss)
    }
  } else {
    return '00:00:00'
  }
}
