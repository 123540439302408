/*
 * @Author: duanyunlong
 * @since: 2020-07-16 17:04:31
 * @lastTime: 2020-07-28 10:18:56
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\router\modules\index.js
 * @message: 首页
 */
const INDEX = [{
  path: '/demo',
  name: 'demo',
  // 按需加载路由
  component: () => import('@/views/demo/App'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: false,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: []
  }
}, {
  path: '/test',
  name: 'test',
  // 按需加载路由
  component: () => import('@/views/test'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: false,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: []
  }
},
{
  path: '/privateletter',
  name: 'privateletter',
  // 按需加载路由
  component: () => import('@/views/demo/privateletter'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: false,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: []
  }
},
{
  path: '/wechat',
  name: 'wechat',
  // 按需加载路由
  component: () => import('@/views/wechat'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: false,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: []
  }
},
{
  path: '/landingpage',
  name: 'landingpage',
  // 按需加载路由
  component: () => import('@/views/landingpage'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: false,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: []
  }
},
{
  path: '/videocall',
  name: 'Videocall',
  // 按需加载路由
  component: () => import('@/views/demo/Videocall'),
  // meta，具体用法请参照vue-router文档
  meta: {
    // 是否需要登录访问
    isLogin: false,
    // 是否需要鉴权
    isAuth: false,
    btnAuthList: []
  }
}
]
export default INDEX
