import { requestPost, requestGet, requestPut, requestDelete } from '../../../utils/request'
import API_KEY from '../../index'
import REQUEST_KEY from '../../../config/request'
// import REQUEST_KEY from '../../../config/request'
// 首页接口方法
export function postuserDoctorAdvertising (params) {
  return requestPost(API_KEY.user.userDoctorAdvertising, params, REQUEST_KEY.requestMode.json)
}
export function postadministrativeOffice (params) {
  return requestPost(API_KEY.user.administrativeOffice, params, REQUEST_KEY.requestMode.json)
}
// 医院接口
export function posthospital (params) {
  return requestPost(API_KEY.user.hospital, params, REQUEST_KEY.requestMode.json)
}
// 根据医院id查科室
export function getAdministrativeById (params) {
  return requestPost(API_KEY.user.getAdministrativeById, params, REQUEST_KEY.requestMode.json)
}
// 根据科室id查医生
export function userDoctor (params) {
  const other = {
    // 是否开启遮盖
    isCover: true,
    // 后台返回给前端请求成功标志code
    code: '0',
    // 返回的数据存储位置
    data: 'data',
    // 通过哪个标识为判断依据
    codeText: 'code',
    // 是否自行处理错误
    isError: false
  }
  return requestPost(API_KEY.user.userDoctor, params, REQUEST_KEY.requestMode.json, REQUEST_KEY.responseType.json, other)
}
// 根据科室id查医生
export function userDoctorId (pk) {
  return requestGet(API_KEY.user.userDoctorId + pk, {})
}
// 根据科室id查医院
export function gethospitalId (params) {
  return requestPost(API_KEY.user.gethospitalId, params, REQUEST_KEY.requestMode.json)
}
// 上传文件
export function uploadFile (params) {
  return requestPost(API_KEY.user.uploadFile, params, REQUEST_KEY.requestMode.json)
}
export function uploadFileList (params) {
  return requestPost(API_KEY.user.uploadFileList, params, REQUEST_KEY.requestMode.json)
}
// 新增医患用户
export function userDoctorPatients (params) {
  return requestPost(API_KEY.user.userDoctorPatients, params, REQUEST_KEY.requestMode.json)
}
// 查询医患信息
export function userDoctorPatientspage (params) {
  return requestPost(API_KEY.user.userDoctorPatientspage, params, REQUEST_KEY.requestMode.json)
}
// 查询医生评论
export function userDoctorCommentpage (params) {
  return requestPost(API_KEY.user.userDoctorCommentpage, params, REQUEST_KEY.requestMode.json)
}
// 查询前两条评论
export function userDoctorComment (params) {
  return requestPost(API_KEY.user.userDoctorComment, params, REQUEST_KEY.requestMode.json)
}
// 新增订单
export function orderRegisteredMail (params) {
  const other = {
    // 是否开启遮盖
    isCover: false,
    // 后台返回给前端请求成功标志code
    code: '0',
    // 返回的数据存储位置
    data: 'data',
    // 通过哪个标识为判断依据
    codeText: 'code',
    // 是否自行处理错误
    isError: true
  }
  return requestPost(API_KEY.user.orderRegisteredMail, params, REQUEST_KEY.requestMode.json, REQUEST_KEY.responseType.json, other)
}
// 微信订单
export function getWXOAuth2Code () {
  return requestGet(API_KEY.user.getWXOAuth2Code, {})
}
// 根据医生id查预约表
export function trainerTimetable (pk) {
  return requestGet(API_KEY.user.trainerTimetable + pk, {})
}
// 预约生成id
export function gettrainerTimetable (params) {
  const other = {
    // 是否开启遮盖
    isCover: false,
    // 后台返回给前端请求成功标志code
    code: '0',
    // 返回的数据存储位置
    data: 'data',
    // 通过哪个标识为判断依据
    codeText: 'code',
    // 是否自行处理错误
    isError: true
  }
  return requestPost(API_KEY.user.gettrainerTimetable, params, REQUEST_KEY.requestMode.json, REQUEST_KEY.responseType.json, other)
}
// 预约挂号新增订单
export function trainerTimetableinsertData (params) {
  const other = {
    // 是否开启遮盖
    isCover: true,
    // 后台返回给前端请求成功标志code
    code: '0',
    // 返回的数据存储位置
    data: 'data',
    // 通过哪个标识为判断依据
    codeText: 'code',
    // 是否自行处理错误
    isError: true
  }
  return requestPost(API_KEY.user.trainerTimetableinsertData, params, REQUEST_KEY.requestMode.json, REQUEST_KEY.responseType.json, other)
}
// 微信回调登录
export function WxLogin (params) {
  return requestGet(API_KEY.user.WxLogin, params)
}
// 查询单条信息
export function adminGetRoamMsg (params) {
  return requestGet(API_KEY.user.adminGetRoamMsg, params)
}
// 单发单聊请求
export function getWebSdkApi (params) {
  return requestGet(API_KEY.user.getWebSdkApi, params)
}
// 生成userSig
export function getUserSig (params) {
  return requestGet(API_KEY.user.getUserSig, params)
}
// 撤回
export function adminMsgWithDraw (params) {
  return requestGet(API_KEY.user.adminMsgWithDraw, params)
}
// 医生认证
export function userDoctorCertified (params) {
  return requestPost(API_KEY.user.userDoctorCertified, params, REQUEST_KEY.requestMode.json)
}
// 医陪认证
export function userMedicalCompanion (params) {
  return requestPost(API_KEY.user.userMedicalCompanion, params, REQUEST_KEY.requestMode.json)
}
// 查询数据字典类别
export function listRootDictionary () {
  return requestGet(API_KEY.user.listRootDictionary, {})
}
// 根据key查询职称
export function listDictionaryByRootCode (pk) {
  return requestGet(API_KEY.user.listDictionaryByRootCode + pk, {})
}
// 微信支付
export function WxJsApi (params) {
  return requestGet(API_KEY.user.WxJsApi, params)
}
// 短信验证
export function MagApi (params) {
  return requestGet(API_KEY.user.MagApi, params)
}
// 根据用户id查询问诊订单
export function orderRegisteredMailpageUserId (params) {
  return requestPost(API_KEY.user.orderRegisteredMailpageUserId, params, REQUEST_KEY.requestMode.json)
}
// 根据医患id查询医患信息
export function getuserDoctorPatients (pk) {
  return requestGet(API_KEY.user.userDoctorPatients + pk, {})
}
// 修改医患信息
export function modifyPatients (params) {
  return requestPut(API_KEY.user.userDoctorPatients, params, REQUEST_KEY.requestMode.json)
}
// 删除医患信息
export function deletePatients (pk) {
  return requestDelete(API_KEY.user.userDoctorPatients + pk, {})
}
// 微信充值
export function rechargeWechat (params) {
  return requestGet(API_KEY.user.rechargeWechat, params)
}
// 根据用户id查询问诊订单
export function appOrderRegisteredMailgetByTypePage (params) {
  return requestPost(API_KEY.user.appOrderRegisteredMailgetByTypePage, params, REQUEST_KEY.requestMode.json)
}
// 查询用户余额
export function byAccountUsergetBy () {
  return requestGet(API_KEY.user.byAccountUsergetBy, {})
}
// 修改余额订单支付状态
export function orderRegisteredMailupdateByOrderIdye (params) {
  return requestPut(API_KEY.user.orderRegisteredMailupdateByOrderIdye, params, REQUEST_KEY.requestMode.formData)
}
// 修改订单支付状态
export function orderRegisteredMailupdateByOrderId (params) {
  return requestPut(API_KEY.user.orderRegisteredMailupdateByOrderId, params, REQUEST_KEY.requestMode.formData)
}
// 取消订单信息
export function orderRegisteredMailcancelOrder (params) {
  return requestPut(API_KEY.user.orderRegisteredMailcancelOrder, params, REQUEST_KEY.requestMode.formData)
}
// 修改订单方式
export function orderRegisteredMailupdateData (params) {
  return requestPut(API_KEY.user.orderRegisteredMailupdateData, params, REQUEST_KEY.requestMode.json)
}
// 查询钱包充值记录
export function OrderWater (params) {
  return requestPost(API_KEY.user.OrderWater, params, REQUEST_KEY.requestMode.json)
}
// 修改问诊订单状态
export function OrderRegisteredMail (params) {
  return requestPost(API_KEY.user.OrderRegisteredMail, params, REQUEST_KEY.requestMode.json)
}
// 余额支付
export function balanceApiPay (params) {
  return requestGet(API_KEY.user.balanceApiPay, params)
}
// 根据用户ID查询医陪人员
export function getByUserId () {
  return requestGet(API_KEY.user.getByUserId, {})
}
// 查询医陪订单
export function medicalOrderPage (params) {
  return requestPost(API_KEY.user.medicalOrderPage, params, REQUEST_KEY.requestMode.json)
}
// 修改医陪订单
export function updateByOrderState (params) {
  return requestPut(API_KEY.user.updateByOrderState, params, REQUEST_KEY.requestMode.json)
}
// 新增订单评价
export function UserDoctorCommentaddComment (params) {
  return requestPost(API_KEY.user.UserDoctorCommentaddComment, params, REQUEST_KEY.requestMode.json)
}
// 修改订单挂号状态
export function updateByOrderStatus (params) {
  return requestPost(API_KEY.user.updateByOrderStatus, params, REQUEST_KEY.requestMode.json)
}
// 订单退款
export function WxRefundApi (params) {
  return requestGet(API_KEY.user.WxRefundApi, params)
}
// 新增充值记录
export function addOrderWater (params) {
  return requestPost(API_KEY.user.addOrderWater, params, REQUEST_KEY.requestMode.json)
}
// 医院广告位
export function userOrgAdvertising (params) {
  return requestPost(API_KEY.user.userOrgAdvertising, params, REQUEST_KEY.requestMode.json)
}
// 根据医院广告位主键id查询医院信息
export function OrgAdvertising (id) {
  return requestGet(API_KEY.user.OrgAdvertising + id)
}
// 广告位医院详情页
export function getOrgDetail (params) {
  return requestGet(API_KEY.user.getOrgDetail, params)
}
// 分页查询客户中心标题
export function pageDateServiceCentre (params) {
  const other = {
    // 是否开启遮盖
    isCover: true,
    // 后台返回给前端请求成功标志code
    code: '0',
    // 返回的数据存储位置
    data: 'data',
    // 通过哪个标识为判断依据
    codeText: 'code',
    // 是否自行处理错误
    isError: false
  }
  return requestPost(API_KEY.user.pageDateServiceCentre, params, REQUEST_KEY.requestMode.json, REQUEST_KEY.responseType.json, other)
}
// 判断该患者该时间是否存预约
export function trainerTimetablegetByTimeJudge (params) {
  const other = {
    // 是否开启遮盖
    isCover: false,
    // 后台返回给前端请求成功标志code
    code: '0',
    // 返回的数据存储位置
    data: 'data',
    // 通过哪个标识为判断依据
    codeText: 'code',
    // 是否自行处理错误
    isError: true
  }
  return requestPost(API_KEY.user.trainerTimetablegetByTimeJudge, params, REQUEST_KEY.requestMode.json, REQUEST_KEY.responseType.json, other)
}
// 修改订单支付状态
export function chattingRecordsaddChatting (params) {
  return requestPost(API_KEY.user.chattingRecordsaddChatting, params, REQUEST_KEY.requestMode.formData)
}
// 查询用户余额
export function WxChatSharegetUrl (params) {
  return requestGet(API_KEY.user.WxChatSharegetUrl, params)
}
// 根据用户ID查询相关信息
export function AccountUserInfogetUserInfo () {
  return requestGet(API_KEY.user.AccountUserInfogetUserInfo, {})
}
// 根据用户ID查询相关信息
export function AccountUserInfo (params) {
  return requestPut(API_KEY.user.AccountUserInfo, params, REQUEST_KEY.requestMode.json)
}
// 分页查询医生视频价格
export function UserDoctorPricepageVideoPrice (params) {
  return requestPost(API_KEY.user.UserDoctorPricepageVideoPrice, params, REQUEST_KEY.requestMode.json)
}
// 查询该用户所在位置
export function getAwaitCount (params) {
  return requestPost(API_KEY.user.getAwaitCount, params, REQUEST_KEY.requestMode.json)
}
// 查询当天该医生已接单人数
export function getQueueCount (params) {
  return requestPost(API_KEY.user.getQueueCount, params, REQUEST_KEY.requestMode.json)
}
// 圈子查询
export function getCircle (id, limitType) {
  return requestPost(API_KEY.circle.page, { pageNum: 0, pageSize: 0, limitType: limitType, parentId: id }, REQUEST_KEY.requestMode.json)
}
export function getCircleFirst () {
  return requestPost(API_KEY.circle.pageFirst, { pageNum: 0, pageSize: 0, parentId: '0' }, REQUEST_KEY.requestMode.json)
}
// id查圈子
export function getCircleOne (id) {
  return requestGet(API_KEY.circle.one + id)
}
// 加入圈子
export function joinCircle (id) {
  return requestPost(API_KEY.circle.joinCircle, { fkCommunityCircleId: id }, REQUEST_KEY.requestMode.json)
}
// 文章
export function getArticleList (info) {
  return requestPost(API_KEY.circle.article.page, info, REQUEST_KEY.requestMode.json)
}
// id查文章详情
export function getInquireArticle (id) {
  return requestGet(API_KEY.circle.inquire + id)
}
// 文章详情页评论查询
export function getSelectByPage (info) {
  return requestPost(API_KEY.circle.selectByPage, info, REQUEST_KEY.requestMode.json)
}
// 文章详情页评论查询
export function getSelectByPageSecond (info) {
  return requestPost(API_KEY.circle.selectByPageSecond, info, REQUEST_KEY.requestMode.json)
}
// 文章详情页评论查询
export function getArticleComment (info) {
  return requestPost(API_KEY.circle.articleComment, info, REQUEST_KEY.requestMode.json)
}
// 新增,修改文章详情页点赞转发
export function getArticleFabulous (info) {
  return requestPost(API_KEY.circle.articleFabulous, info, REQUEST_KEY.requestMode.json)
}
// 问答/病友互助
export function getQuestionsList (info) {
  return requestPost(API_KEY.circle.questions.page, info, REQUEST_KEY.requestMode.json)
}
// 新增问答
export function addQuestion (info) {
  return requestPost(API_KEY.circle.questions.one, info, REQUEST_KEY.requestMode.json)
}
export function getQuestionsOne (id) {
  return requestGet(API_KEY.circle.questions.one + id)
}
// 点赞/转发
export function addFabulous (info) {
  return requestPost(API_KEY.circle.fabulous.add, info, REQUEST_KEY.requestMode.json)
}
// 社区分页视频
export function VideoPage (param) {
  return requestPost(API_KEY.video.VideoPage, param, REQUEST_KEY.requestMode.json)
}
// 获取视频凭证
export function getPlayAuth (id) {
  return requestGet(API_KEY.video.getPlayAuth + id)
}
// 获取视频凭证
export function getPlayUrl (id) {
  return requestGet(API_KEY.video.getPlayUrl + id)
}
// 社区视频点赞
export function like (param) {
  return requestPost(API_KEY.video.like, param, REQUEST_KEY.requestMode.json)
}
export function likePage (param) {
  return requestPost(API_KEY.video.likePage, param, REQUEST_KEY.requestMode.json)
}
// 社区视频关注
export function follow (param) {
  return requestPost(API_KEY.video.follow, param, REQUEST_KEY.requestMode.json)
}
// 社区视频评论分页
export function commentpage (param) {
  return requestPost(API_KEY.video.commentpage, param, REQUEST_KEY.requestMode.json)
}
// 社区视频新增评论
export function addComment (param) {
  return requestPost(API_KEY.video.addComment, param, REQUEST_KEY.requestMode.json)
}
// 社区用户详情
export function userPage (param) {
  return requestPost(API_KEY.video.userPage, param, REQUEST_KEY.requestMode.json)
}
// 我的收藏
export function collect (param) {
  return requestPost(API_KEY.video.collect, param, REQUEST_KEY.requestMode.json)
}
// 标签
export function labelList () {
  return requestPost(API_KEY.circle.label.page, { pageNum: 0, pageSize: 0 }, REQUEST_KEY.requestMode.json)
}
// 回复评论
export function addReply (param) {
  return requestPost(API_KEY.video.addReply, param, REQUEST_KEY.requestMode.json)
}
// 分页查询回复
export function replyPage (param) {
  return requestPost(API_KEY.video.replyPage, param, REQUEST_KEY.requestMode.json)
}
// 根据视频id查询视频信息
export function videogetById (id) {
  return requestGet(API_KEY.video.videogetById + id + '?type=0')
}
// 新增标签
export function addLabel (info) {
  return requestPost(API_KEY.circle.label.one, info, REQUEST_KEY.requestMode.json)
}
// 问答评论分页
export function questionCommentPage (info) {
  return requestPost(API_KEY.circle.questions.commentPage, info, REQUEST_KEY.requestMode.json)
}
// 问答评论分页
export function questioncommentPageSecond (info) {
  return requestPost(API_KEY.circle.questions.commentPageSecond, info, REQUEST_KEY.requestMode.json)
}
// 新增问答评论
export function addQuestionComment (info) {
  return requestPost(API_KEY.circle.questions.addComment, info, REQUEST_KEY.requestMode.json)
}
// 圈子轮播数据
export function carouselList (id) {
  return requestGet(API_KEY.circle.carousel + id)
}
// 新增圈子
export function addCircle (info) {
  return requestPost(API_KEY.circle.insert, info, REQUEST_KEY.requestMode.json)
}
// 标签查圈子
export function getCircleByLabel (param) {
  return requestPost(API_KEY.circle.label.selectCircles, param, REQUEST_KEY.requestMode.json)
}
// 标签查圈子问答
export function getQuestionsByLabel (param) {
  return requestPost(API_KEY.circle.label.selectQuestions, param, REQUEST_KEY.requestMode.json)
}
// 社区视频分享
export function share (param) {
  return requestPost(API_KEY.video.share, param, REQUEST_KEY.requestMode.json)
}
// 用户端 我的主页(有统计数)
export function userAndCountPage (param) {
  return requestPost(API_KEY.video.userAndCountPage, param, REQUEST_KEY.requestMode.json)
}
// 直播分页查询
export function livegetPage (params) {
  return requestPut(API_KEY.live.livegetPage, params, REQUEST_KEY.requestMode.json)
}
// 通过房间号查询直播详情
export function livegetDetails (params) {
  return requestGet(API_KEY.live.livegetDetails + params)
}
//  创建直播
export function CommunityLiveStreaming (param) {
  return requestPost(API_KEY.live.CommunityLiveStreaming, param, REQUEST_KEY.requestMode.json)
}
// 直播结束
export function endLive (params) {
  return requestPut(API_KEY.live.endLive, params, REQUEST_KEY.requestMode.json)
}
// 我的关注/我的粉丝
export function getAttentionAndFans (param) {
  return requestPost(API_KEY.mine.attentionAndFans, param, REQUEST_KEY.requestMode.json)
}
// 取消关注
export function cancelAttention (param) {
  return requestPost(API_KEY.mine.cancelAttention, param, REQUEST_KEY.requestMode.json)
}
// 我的统计
export function countInfo (param) {
  return requestPost(API_KEY.mine.countInfo, param, REQUEST_KEY.requestMode.json)
}
// 人气点赞
export function popularity (params) {
  return requestGet(API_KEY.live.popularity + params)
}
// 获取人气
export function getLiveAndPopularity (params) {
  return requestGet(API_KEY.live.getLiveAndPopularity + params)
}
// 获取用户头像
export function getUserPortrait (params) {
  return requestGet(API_KEY.live.getUserPortrait + params + '?size=3')
}
// 退出直播
export function signOut (params) {
  return requestGet(API_KEY.live.signOut + params)
}
// 查询直播礼物列表
export function Giftpage (param) {
  return requestPost(API_KEY.live.Giftpage, param, REQUEST_KEY.requestMode.json)
}
// 查询详情
export function getStreamingGift (params) {
  return requestGet(API_KEY.live.getStreamingGift + params)
}
// 打赏
export function toGiftreward (id, liveId) {
  return requestGet(API_KEY.live.Giftreward + id + '?liveId=' + liveId + '&number=1')
}
// 新增聊天记录
export function addepic (param) {
  return requestPost(API_KEY.video.addepic, param, REQUEST_KEY.requestMode.json)
}
// 查询聊天记录
export function epicpage (param) {
  return requestPost(API_KEY.video.epicpage, param, REQUEST_KEY.requestMode.json)
}
// 新增私信组
export function epicgroup (param) {
  return requestPost(API_KEY.video.epicgroup, param, REQUEST_KEY.requestMode.json)
}
// 查询私信组
export function epicgrouppage (param) {
  return requestPost(API_KEY.video.epicgrouppage, param, REQUEST_KEY.requestMode.json)
}
