/**
 * 菜单api
 */
import { requestGet } from '../../../utils/request'
import API_KEY from '../../index'

/**
 * 获取当前用户菜单
 */
export function getCurrMenu () {
  return requestGet(API_KEY.menu.findMyMenu, { siteCode: 'manage' })
}

/**
 * 根据id获取菜单信息
 * @param id
 */
export function getMenuInfoById (id) {
  return requestGet(API_KEY.menu.crudMenu + '' + id, {})
}

export function getMenuSelect () {
  return requestGet(API_KEY.menu.readMenuSelect, {})
}
